@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');


body{
  background-color: #f5f5f5;
}



.nav_logo{
  width: 266px;
  height: 47px;
  margin-top: 9px;
  padding-left: 10px
}

.logo-slide{
  width: 238px;
  height: 238px
}

.text-title-principal{
  font-family: 'Vollkorn', serif;
  color: #0D47A1
}



.btn_admision a{
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;


}

.nosotros{
  margin-top: 80px;
  color: #474c53;
}

.logo_admision{
  max-width: 240px;
  max-height: 240px;
}

.titulo_seccion h4{
  color: #0D47A1;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.card-content{
  color: #636779;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
}

.color-row{
  padding-top: 10px;
  padding-bottom: 40px;
  margin-bottom: 0px;
  background: rgb(13,71,161);
  background: linear-gradient(117deg, rgba(13,71,161,1) 0%, rgba(13,149,161,1) 100%);
}

.color-row h4{
  color: #F5f5f5;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.destacado_title{
  color: #FDD835;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.destacado_text{
  color: #ffffff;
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
}

.galeria_row{
  margin-top: 0px !important;
  margin-bottom: 0px !important
}

.galeria_img{
  padding: 0px !important;
  margin-bottom: 0px !important
}

.row_footer{
  border-top: 10px solid #FDD835;
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: #1F3049;
  margin-bottom: 0px;
}

.logo_footer{
  max-width: 128px;
  max-height: 128px;
}

.text_footer{
  color: #ffffff;
  opacity: 0.5;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
}

.footer_info{
  padding: 20px
}

.row_oxibrand{
  background-color: #101823;
  margin-top: 0px;
  padding-top: 6px;
  padding-left: 5px;
  margin-bottom: 0px;
  color: rgba(255,255,255,0.3);


}
.img_new_reciente{
  width: 160px !important;
  height: 160px !important;
}

.contact_box{
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px !important;
  margin-top: 90px;
}

.card-title-contact{
  color: #0d47a1;
}

.logo_quienes{
  width: 180px;
  height: auto
}

.quienes{
  margin-top: 30px
}

.mapa{
  margin-top: 60px;

}
